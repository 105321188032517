import React, { useCallback, useEffect, useState } from "react";
import LoginContent from "../../components/LoginContent";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import { graphql, navigate, Script } from "gatsby";
import Layout from "../../components/layout/Layout";
import { getQueryString } from "../../utils/helper";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";
import ErrorBoundary from "../../utils/ErrorBoundary/ErrorBoundary";
import Seo from "../../components/Seo";
import { useLocation } from "@reach/router";

const Login = () => {
	const location = useLocation();
	const { isLoggedIn, userId, email } = useJWTChecker();
	// const userDetails = JWTChecker();
	const [userStatus, setUserStatus] = useState(true); // {*** FALSE -> Register ** TRUE -> Login ***}
	const [showMyAccount, setShowMyAccount] = useState(false);

	useEffect(() => {
		if (isLoggedIn && localStorage.getItem("user")) {
			// navigate(`/my-account` + location.search);
			navigate(`/my-account/${location.search}`);
		}
	}, [isLoggedIn]);

	const setAuthType = useCallback(() => {
		const redirectUrl = getQueryString("r");

		let url = "/register";

		if (redirectUrl) {
			url = `${url}?r=${redirectUrl}`;
		}

		navigate(url + location.search);
	}, []);

	return (
		<ErrorBoundary>
			<Layout>
				<LoginContent goToRegister={setAuthType} />
			</Layout>
		</ErrorBoundary>
	);
};

export const query = graphql`
	query {
		wp {
			isSearchEngineEnabled
		}
	}
`;

export const Head = ({ data }: any) => {
	const isSearchEnabled = data.wp.isSearchEngineEnabled;
	const location = useLocation();
	return (
		<>
			<title>Login</title>
			<meta name="robots" content={!isSearchEnabled ? "noindex, nofollow" : "index, follow"} />
			<link rel="canonical" href={`${location.origin}${location.pathname}`}></link>
		</>
	);
};

export default Login;
